import styled from 'styled-components'

export const StyledPaymentOptions = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0 10px;
  .payment-option-title {
    text-align: center;
    max-width: 1124px;
    margin-top: auto;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    margin-bottom: 30px;

    b {
      font-weight: var(--brand-font-weight-normal);
    }
  }
  .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .payment-option-wrapper {
      -webkit-box-shadow: 0 6.42857px 12.8571px rgb(0 0 0 / 7%);
      border-radius: 2px;
      box-shadow: 0 6.42857px 12.8571px rgb(0 0 0 / 7%);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px 13px;
      width: 35px;
    }
    .payment-option {
      width: 100%;
      height: auto;
    }
  }
  @media screen and (min-width: 768px) {
    margin-bottom: 100px;
    .options {
      .payment-option-wrapper {
        border-radius: 20px;
        width: 98px;
        height: 98px;
      }
      .payment-option {
        margin: 0 10px;
      }
    }
  }
`
