import isEmpty from 'lodash/isEmpty'
import React from 'react'

import OptimizedImage from '../../../ComponentsV2/common/OptimizedImage'
import Typography from '../../../ComponentsV2/common/Typography/Typography'
import {StyledPaymentOptions} from './PaymentOptions.styles'

function PaymentOptions(props) {
  const {title, options, sectionName} = props.state || {}
  if (isEmpty(props.state) || isEmpty(options)) {
    return null
  }

  return (
    <StyledPaymentOptions data-sectionname={sectionName}>
      <div className="payment-option-title">
        <Typography text={title} variant="body-base-regular" />
      </div>
      <div className="options">
        {options.map((option) => {
          const {src, width, height, altText} = option || {}
          return (
            <span className="payment-option-wrapper" key={altText}>
              <OptimizedImage
                source={src}
                aspectWidth="98"
                aspectHeight="55"
                desktopViewWidth="SMALL"
                mobileViewWidth="SMALL"
                altText={altText}
                loading="lazy"
                customClassName="payment-option"
              />
            </span>
          )
        })}
      </div>
    </StyledPaymentOptions>
  )
}

export default PaymentOptions
